import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
                light: {
                    primary: '#49688B',
                    secondary: '#2E4D6F',
                    anchor: '#3F679B',
                    accent: '#6784AC',
                    error: '#C12327',
                    info: '#9CB6DC',
                    success: '#40815B',
                    warning: '#FFC600',

                    stonewood_primary: '#004976',
                    stonewood_secondary: '#02C6C1',
                    stonewood_complementary: '#005381',
                    stonewood_orange: '#FAC642',
                    
                    white: '#ffffff',
                    lightgray: '#DFE1E2',
                    darkgray: '#808285',
                    darkergray: '#444444',
                    black: '#000000',
                },
            },
        }
});