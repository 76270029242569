import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import "./plugins/vuetify-money.js";
import Vuex from 'vuex'
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

import router from './router'

Vue.config.productionTip = false

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    company:{
      company_name :"",
      lead_allow: false,
      meeting_url : "",
      userFullName : "",
      phone :"",
      lead_capture_disclosure : "",
      gtm_code : ""
    },
    user: {
      first_name: "",
      last_name: "",
      age: 0,
      taxDeferred: 0,
      taxRate: 0,
      taxPaid:0,
      totalTaxesConversion:0,
      email: "",
      advisorId:"",
      proposalId: ""
    }
  },
  
  mutations: {
    increment (state) {
      state.count++
    }
  }
})



new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')