<template>
    <v-card
        class="mx-auto my-4 borderThick fill-height"
        elevation="6"
        outlined
        :class=" isCalculated ? 'border-darkgray' : 'border-stonewood-primary'"
    >
            <v-card-title v-if="isCalculated" class="white--text stonewood_secondary darken-3">
                <span class="text-h3">
                    Total Potential Taxes with Adjustments <sup>2</sup>
                </span>
            </v-card-title>
            <v-card-title  v-else class="white--text  stonewood_primary">
                <span class="text-h3">
                    Total Potential Taxes with Existing Approach <sup>1</sup>
                </span>
            </v-card-title>
            <v-card-text class="pt-4 text-center d-flex align-stretch flex-column fill-height"  v-if="isCalculated" >
                <span class="text-h1">{{ totalTaxesConversion | currency('$', 0) }}</span>
            </v-card-text>
            <v-card-text class="pt-4 text-center d-flex align-stretch flex-column fill-height"  v-else>
                <span class="text-h1">{{ totalTax | currency('$', 0) }}</span>
            </v-card-text>
        </v-card>
</template>
<script>
import Vue from 'vue'
import Vue2Filters from 'vue2-filters'
Vue.use(Vue2Filters)

export default {
    name: 'calcCardCondense',
    props: {
        isCalculated: {
            type: Boolean, 
            default: false
        }
    },
    data: () => ({
        fundsAnalized: "",
        taxRate: "",
    }),
    created() {    
        this.totalTax = this.$store.state.user.taxPaid;
        this.totalTaxesConversion = this.$store.state.user.totalTaxesConversion;
        
    },
}
</script>