<template>
    <v-container fluid class="hero" :style="{backgroundImage: 'url('+require('@/assets/images/herobg.png')}" >
       
            <v-row class="align-center">
                <v-col cols="12" md="6" class="align-self-end">
                    <div class="">
                        <v-card
                            class="mb-5 ml-n3"
                            color="stonewood_complementary"
                            dark
                            max-width="100%"
                        >
                            <v-card-text class="text-h3 text-md-h1 white--text font-weight-medium d-flex flex-column pa-0">
                                <div id="card-title-h3" class="pa-md-5 pa-2 ml-auto mr-7 text-left d-flex flex-wrap">
                                    <span class="single-line">COULD YOUR&nbsp;</span> <span class="single-line">TAX BILL IN</span>
                                     <br/>
                                    <span class="single-line">RETIREMENT BE <span class="font-weight-black d-inline-block">TOO BIG ?</span></span>
                                </div>
                                <v-divider inset class="sw_orange hero-separator" ></v-divider>
                                <div class=" d-flex align-center pa-2 pa-md-8 text-h6 text-md-h4 font-weight-regular action-subheading">
                                     <v-icon x-large color="stonewood_orange">mdi-arrow-right</v-icon> FIND OUT IN 30 SECONDS
                                </div>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end">
                                    <v-btn text v-on:click=" goToLearnMoreEvent()" color="white"> LEARN MORE</v-btn>
                            </v-card-actions>
                        </v-card>
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <CalculationForm :formFocus="focusOnForm"></CalculationForm>            
                </v-col>
                 
            </v-row>
    </v-container>
</template>
<script>
import CalculationForm from "../components/Form.vue";

export default {
    components: {
        CalculationForm,
    },
    data: () => ({
        heroImage: require("@/assets/images/herobg.png"),
        focusOnForm: false,
    }),
    methods: {
        goToForm() {
            this.focusOnForm = false;
            this.$nextTick(() => {
                this.focusOnForm = true;
            });
        },
        goToLearnMoreEvent() { 
            this.$emit('event');
        }
    }
}
</script>