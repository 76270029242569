<template>
<div class="page-wrapper">
  <HeroPage ref="HeroPageComp"  @event="goToLearnMore"></HeroPage>
  <v-container>
    <v-row class="pt-5">
      <v-col cols="12">

        <v-row class="gray--text mt-4" id="learnMore">
          <v-col cols="12" md="6">
            <p class="text-justify">
              If you’ve saved for retirement in an IRA, 401(k) or other
              tax-deferred vehicle, you have a tax bill due in retirement.
              That’s because you’ve deferred your taxes to the future.
            </p>
            <p class="text-justify">
              Do you know the size of your potential tax bill?<br />Find out in
              3 easy steps:<br />
            </p>
            <ol type="1">
              <li>Enter your information into the calculator</li>
              <li>Immediately see your potential retirement tax bill</li>
              <li>Discover options to address it</li>
            </ol>
            <div class="text-center mt-4">
              <v-btn color="primary" @click="$refs.HeroPageComp.goToForm()">
                        Find out now
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <iframe
              style="width: 100%; height: 100%"
              src="https://player.vimeo.com/video/605875159?h=1e06f9d73c"
              frameborder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
            ></iframe>
           
          </v-col>
        </v-row>
      </v-col>
      
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center"> </v-col>
    </v-row>
    <v-row class="gray--text">
      <v-col>
        <span id="mainForm"></span>
      
      </v-col>
    </v-row>

    <v-row>
      <v-col> </v-col>
    </v-row>
  </v-container>

  <v-dialog
      v-model="beforeExitDialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 d-flex justify-space-between">
          Wait!
          <v-btn
            icon
            light
            @click="beforeExitDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="mt-4">
          <p>Do you need help with the calculator?</p>
          <p>If you have questions about your tax rate or retirement account, let us help.</p>
          <p>Call our office at {{company_phone}} or click <a :href="meeting_url" target="_blank">here</a>   to schedule a phone call.</p>
          <p> We’ll make sure you’re informed about your future.</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="beforeExitDialog = false"
          >Close</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>

</div>
</template>
<script>
import HeroPage from "../components/heropage.vue";
import $ from 'jquery'


export default {
  components: {
   HeroPage
  },
  data: () => ({
    placeholderimg: require("@/assets/images/stonewood.png"),
    vimeoVidID: "566192129",
    beforeExitDialog: false,
    company_name: "",
    meeting_url: "",
    company_phone: "" 
  }),
  updated() {
    this.$nextTick(function () {
     this.company_name = this.$store.state.company.company_name;
    this.company_phone = this.$store.state.company.phone;
    this.meeting_url = this.$store.state.company.meeting_url;

    })
  },
  created() {

    this.company_name = this.$store.state.company.company_name;
    this.company_phone = this.$store.state.company.phone;
    this.meeting_url = this.$store.state.company.meeting_url;

    var beforeUnloadTimeout = 0 ;
    

    let proxy = this;
    $(window).bind('beforeunload', function()  {
        console.log('beforeunload');
        beforeUnloadTimeout = setTimeout(function() {
            proxy.onUnloadCheckUp(true);
            console.log('settimeout function');
        },500);
        return 'are you sure';
    });
    $(window).bind('unload', function() {
        console.log('unload');
        if(typeof beforeUnloadTimeout !=='undefined' && beforeUnloadTimeout != 0)
            clearTimeout(beforeUnloadTimeout);
    });
    
  },
  methods: {
    goToForm() {
      this.focusOnForm = true;
    },
    goToLearnMore() {
        let element = document.getElementById("learnMore");
        element.scrollIntoView(false);
    },
    onUnloadCheckUp(state){
      let proxy = this;
      proxy.beforeExitDialog = state;
    }   
  }
};
</script>